.adm-picker {
  --header-button-font-size: var(--adm-font-size-7);
  --title-font-size: var(--adm-font-size-7);
  --item-font-size: var(--adm-font-size-8);
  --item-height: 34px;
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  ---item-font-size: var(--item-font-size);
  ---item-height: var(--item-height);
}
.adm-picker .adm-picker-view.adm-picker-view {
  --item-font-size: var(---item-font-size);
  --item-height: var(---item-height);
}
.adm-picker-header {
  flex-shrink: 0;
  border-bottom: solid 1px var(--adm-color-border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 4px;
}
.adm-picker-header-button {
  font-size: var(--header-button-font-size);
  display: inline-block;
  padding: 8px 8px;
}
.adm-picker-header-button-disabled,
.adm-picker-header-button-disabled:active {
  opacity: 0.4;
  cursor: not-allowed;
}
.adm-picker-header-title {
  padding: 4px 4px;
  font-size: var(--title-font-size);
  color: var(--adm-color-text);
  text-align: center;
  flex: 1;
}
.adm-picker-body {
  flex: 1;
  width: 100%;
  height: 100%;
}
.adm-picker-body > .adm-picker-view {
  --height: 100%;
}
.adm-picker-popup .adm-popup-body {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
