.adm-divider-horizontal {
  display: flex;
  align-items: center;
  margin: 16px 0;
  border-width: 0;
  border-color: var(--adm-color-border);
  border-style: solid;
  color: var(--adm-color-weak);
  font-size: 14px;
}
.adm-divider-left.adm-divider-horizontal::before {
  max-width: 10%;
}
.adm-divider-right.adm-divider-horizontal::after {
  max-width: 10%;
}
.adm-divider-horizontal::after,
.adm-divider-horizontal::before {
  flex: auto;
  display: block;
  content: '';
  border-style: inherit;
  border-color: inherit;
  border-width: 1px 0 0;
}
.adm-divider-horizontal .adm-divider-content {
  flex: none;
  padding: 0 16px;
}
.adm-divider-vertical {
  position: relative;
  top: -0.06em;
  display: inline-block;
  height: 0.9em;
  margin: 0 16px;
  vertical-align: middle;
  border-top: 0;
  border-left: 1px solid var(--adm-color-border);
}
