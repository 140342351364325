.adm-safe-area {
  --multiple: var(--adm-safe-area-multiple, 1);
  display: block;
  width: 100%;
}
.adm-safe-area-position-top {
  padding-top: calc(env(safe-area-inset-top) * var(--multiple));
}
.adm-safe-area-position-bottom {
  padding-bottom: calc(env(safe-area-inset-bottom) * var(--multiple));
}
