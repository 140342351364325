.adm-button {
  --color: var(--adm-color-text-light-solid);
  --text-color: var(--adm-button-text-color, var(--adm-color-text));
  --background-color: var(--adm-button-background-color, var(--adm-color-background));
  --border-radius: var(--adm-button-border-radius, 4px);
  --border-width: var(--adm-button-border-width, 1px);
  --border-style: var(--adm-button-border-style, solid);
  --border-color: var(--adm-button-border-color, var(--adm-color-border));
  color: var(--text-color);
  background-color: var(--background-color);
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: auto;
  padding: 7px 12px;
  margin: 0;
  font-size: var(--adm-font-size-9);
  line-height: 1.4;
  text-align: center;
  border: var(--border-width) var(--border-style) var(--border-color);
  border-radius: var(--border-radius);
  cursor: pointer;
  transition: opacity ease 0.15s;
  -webkit-user-select: none;
          user-select: none;
}
.adm-button:focus {
  outline: none;
}
.adm-button::before {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(calc(var(--border-width) * -1), calc(var(--border-width) * -1));
  width: 100%;
  height: 100%;
  background-color: var(--adm-color-text-dark-solid);
  border: var(--border-width) var(--border-style) var(--adm-color-text-dark-solid);
  border-radius: var(--border-radius);
  opacity: 0;
  content: ' ';
  box-sizing: content-box;
}
.adm-button:active::before {
  opacity: 0.08;
}
.adm-button-default.adm-button-fill-outline {
  --background-color: transparent;
  --border-color: var(--adm-color-text);
}
.adm-button-default.adm-button-fill-none {
  --background-color: transparent;
  --border-width: 0px;
}
.adm-button:not(.adm-button-default) {
  --text-color: var(--adm-color-text-light-solid);
  --background-color: var(--color);
  --border-color: var(--color);
}
.adm-button:not(.adm-button-default).adm-button-fill-outline {
  --text-color: var(--color);
  --background-color: transparent;
}
.adm-button:not(.adm-button-default).adm-button-fill-none {
  --text-color: var(--color);
  --background-color: transparent;
  --border-width: 0px;
}
.adm-button-primary {
  --color: var(--adm-color-primary);
}
.adm-button-success {
  --color: var(--adm-color-success);
}
.adm-button-danger {
  --color: var(--adm-color-danger);
}
.adm-button-warning {
  --color: var(--adm-color-warning);
}
.adm-button-block {
  display: block;
  width: 100%;
}
.adm-button-disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.adm-button-disabled:active::before {
  display: none;
}
.adm-button.adm-button-mini {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: var(--adm-font-size-main);
}
.adm-button.adm-button-mini.adm-button-shape-rounded {
  padding-left: 9px;
  padding-right: 9px;
}
.adm-button.adm-button-small {
  padding-top: 3px;
  padding-bottom: 3px;
  font-size: var(--adm-font-size-7);
}
.adm-button.adm-button-large {
  padding-top: 11px;
  padding-bottom: 11px;
  font-size: var(--adm-font-size-10);
}
.adm-button.adm-button-shape-rounded {
  --border-radius: 1000px;
}
.adm-button.adm-button-shape-rectangular {
  --border-radius: 0;
}
.adm-button-loading {
  vertical-align: bottom;
}
.adm-button-loading-wrapper {
  display: flex;
  height: 1.4em;
  align-items: center;
  justify-content: center;
}
.adm-button-loading-wrapper > .adm-loading {
  opacity: 0.6;
}
