.adm-input {
  --font-size: var(--adm-font-size-9);
  --color: var(--adm-color-text);
  --placeholder-color: var(--adm-color-light);
  --text-align: left;
  --background-color: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  min-height: 24px;
  background-color: var(--background-color);
}
.adm-input-disabled {
  opacity: 0.4;
  cursor: not-allowed;
}
.adm-input-element {
  flex: auto;
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  padding: 0;
  margin: 0;
  color: var(--color);
  font-size: var(--font-size);
  line-height: 1.5;
  background: transparent;
  border: 0;
  outline: none;
  -webkit-appearance: none;
          appearance: none;
  min-height: 1.5em;
  text-align: var(--text-align);
}
.adm-input-element::-webkit-input-placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
}
.adm-input-element::placeholder {
  color: var(--placeholder-color);
  font-family: inherit;
}
.adm-input-element:-webkit-autofill {
  background-color: transparent;
}
.adm-input-element:read-only {
  cursor: default;
}
.adm-input-element:invalid {
  box-shadow: none;
}
.adm-input-element::-ms-clear {
  display: none;
}
.adm-input-element::-ms-reveal {
  display: none;
}
.adm-input-element::-webkit-search-cancel-button {
  display: none;
}
.adm-input-element::-webkit-search-decoration {
  display: none;
}
.adm-input-element:disabled {
  opacity: 1;
}
.adm-input-element[type='date'],
.adm-input-element[type='time'],
.adm-input-element[type='datetime-local'] {
  min-height: 1.5em;
}
.adm-input-element[type='search'] {
  -webkit-appearance: none;
}
.adm-input-element[readonly] {
  pointer-events: none;
}
.adm-input-clear {
  flex: none;
  margin-left: 8px;
  color: var(--adm-color-light);
  padding: 4px;
  cursor: pointer;
}
.adm-input-clear:active {
  color: var(--adm-color-weak);
}
.adm-input-clear .antd-mobile-icon {
  display: block;
  font-size: var(--adm-font-size-7);
}
