.adm-image {
  --width: var(--adm-image-width, auto);
  --height: var(--adm-image-height, auto);
  width: var(--width);
  height: var(--height);
  display: block;
  overflow: hidden;
}
.adm-image-img {
  width: 100%;
  height: 100%;
}
.adm-image-tip {
  position: relative;
  background-color: var(--adm-color-fill-content);
  height: 100%;
  min-height: 24px;
  min-width: 24px;
}
.adm-image-tip > svg {
  width: 24px;
  height: 24px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--adm-color-weak);
}
