.adm-notice-bar {
  --background-color: var(--adm-color-weak);
  --border-color: var(--adm-color-weak);
  --text-color: var(--adm-color-text-light-solid);
  --font-size: var(--adm-font-size-7);
  --icon-font-size: var(--adm-font-size-10);
  --height: 40px;
  height: var(--height);
  box-sizing: border-box;
  font-size: var(--font-size);
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: solid 1px var(--border-color);
  border-left: none;
  border-right: none;
  background-color: var(--background-color);
  color: var(--text-color);
}
.adm-notice-bar > span[role='img'] {
  color: var(--text-color);
}
.adm-notice-bar.adm-notice-bar-alert {
  --background-color: #fff9ed;
  --border-color: #fff3e9;
  --text-color: var(--adm-color-orange);
}
.adm-notice-bar.adm-notice-bar-error {
  --background-color: var(--adm-color-danger);
  --border-color: #d9281e;
  --text-color: #ffffff;
}
.adm-notice-bar.adm-notice-bar-info {
  --background-color: #d0e4ff;
  --border-color: #bcd8ff;
  --text-color: var(--adm-color-primary);
}
.adm-notice-bar .adm-notice-bar-left {
  flex-shrink: 0;
  margin-right: 8px;
  font-size: var(--icon-font-size);
}
.adm-notice-bar .adm-notice-bar-content {
  flex: 1;
  overflow: hidden;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}
.adm-notice-bar .adm-notice-bar-content .adm-notice-bar-content-inner {
  width: auto;
  transition-timing-function: linear;
  white-space: nowrap;
}
.adm-notice-bar-wrap.adm-notice-bar .adm-notice-bar-content .adm-notice-bar-content-inner {
  white-space: normal;
}
.adm-notice-bar .adm-notice-bar-right {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  margin-left: 12px;
}
.adm-notice-bar-close {
  width: 24px;
  height: 24px;
  margin-right: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--adm-font-size-10);
}
.adm-notice-bar-wrap {
  height: auto;
  align-items: flex-start;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 22px;
}
