.adm-spin-loading {
  --color: var(--adm-color-weak);
  --size: 32px;
  width: var(--size);
  height: var(--size);
}
.adm-spin-loading-svg {
  width: 100%;
  height: 100%;
  animation: adm-spin-loading-rotate 0.8s infinite linear;
}
.adm-spin-loading-svg > .adm-spin-loading-fill {
  stroke: var(--color);
}
@keyframes adm-spin-loading-rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
