.adm-card {
  background: var(--adm-color-background);
  border-radius: var(--adm-card-border-radius, 8px);
  padding: 0 var(--adm-card-padding-inline, 12px);
}
.adm-card-header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: var(--adm-card-header-padding-block, 12px) 0;
}
.adm-card-header:not(:last-child) {
  border-bottom: solid var(--adm-card-header-border-width, 0.5px) var(--adm-card-header-border-color, var(--adm-color-border));
}
.adm-card-header-title {
  font-size: var(--adm-font-size-7);
  line-height: 1.4;
  font-weight: bold;
}
.adm-card-body {
  padding: var(--adm-card-body-padding-block, 12px) 0;
}
